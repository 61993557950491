import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App2';
import reportWebVitals from './reportWebVitals';
import Login from './Login';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import Users from './Users';
import CreateUser from './CreateUser';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#333333',
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/users-management/new">
          <CreateUser />
        </Route>
        <Route path="/users-management">
          <Users />
        </Route>
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
