import React, { useState } from 'react';
// import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { authenticate } from "./utils/api";

import logo from "./logo.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  // const intl = useIntl();
  const classes = useStyles();

  const authentication = async () => {
    if(!login) {
        return setError({email: "missing_email"});
    }
    if(!password) {
        return setError({password: "missing_password"});
    }
    const result = await authenticate(login, password);

    if(!result.accessToken) {
        return setError({general: result.message});
    }
    window.location.href = "/";
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} height="40.5" width="100"/>
        <Typography component="h1" variant="h5">
            Connexion
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            error={error && !!error.email}
            helperText={error && error.email}
            fullWidth
            id="email"
            label="Addresse email"
            name="email"
            type="email"
            autoComplete="email"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off'
            }}
            autoFocus
            value={login}
            onChange={e => setLogin(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={error && !!error.password}
            helperText={error && error.password}
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            onClick={authentication}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Connexion
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default Login;