import React, { useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import "./createUser.css";

import client, { getUser } from './utils/api';
import AppBar from './AppBar';
import { Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';

const CreateUser = () => {
    const [ newUser, setNewUser ] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const createNewUser = async () => {
        setError({});
        setLoading(true);
        const { confirmPassword, ...newUserPayload } = newUser;
        if(confirmPassword !== newUser.password) {
            setError({ password: "Passwords need to match"});
            return;
        }

        await client.mainApp.service('users').create({...newUserPayload});
        setLoading(false);
        window.location.href = "/users-management";
    };

    const user = getUser();

    return (<>
    <AppBar name={user?.name} email={user?.email} />
    <form className='create-user-form'>
        <TextField
            variant="outlined"
            margin="normal"
            required
            error={error && !!error.email}
            helperText={error && error.email}
            fullWidth
            id="email"
            label="Addresse email"
            name="email"
            type="email"
            autoComplete="email"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off'
            }}
            autoFocus
            value={newUser.email}
            onChange={e => setNewUser({...newUser, email: e.target.value})}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            error={error && !!error.name}
            helperText={error && error.name}
            fullWidth
            id="name"
            label="Name"
            name="name"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off'
            }}
            autoFocus
            value={newUser.name}
            onChange={e => setNewUser({...newUser, name: e.target.value})}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            error={error && !!error.salesforceId}
            helperText={error && error.salesforceId}
            fullWidth
            id="salesforceId"
            label="Salesforce ID"
            name="salesforceId"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off'
            }}
            autoFocus
            value={newUser.salesforceId}
            onChange={e => setNewUser({...newUser, salesforceId: e.target.value})}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={error && !!error.password}
            helperText={error && error.password}
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            value={newUser.password}
            onChange={e => setNewUser({...newUser, password: e.target.value})}
          />
           <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={error && !!error.password}
            helperText={error && error.password}
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            value={newUser.confirmPassword}
            onChange={e => setNewUser({...newUser, confirmPassword: e.target.value})}
          />
          <FormControlLabel control={<Checkbox 
            label="Is admin" 
            checked={newUser.isAdmin}
            onChange={e => setNewUser({...newUser, isAdmin: e.target.checked})}
          />} label="Is admin" />
          {!loading ? 
          <Button
            onClick={createNewUser}
            fullWidth
            variant="contained"
            color="primary"
          >
            Create
          </Button> : <CircularProgress />}
    </form></>);
};

export default CreateUser;