/* global window, localStorage */
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';

let API_URL = "http://localhost:3030";

if(process.env.NODE_ENV === "production") {
 API_URL = "https://partenaires.jacuzzibrands.fr";
}

const EXPIRE_IN = 86400 * 1000; // 1 day

function getApp(url) {
  const app = feathers();
  // Connect to a different URL
  const restClient = rest(url);

  // Configure an AJAX library (see below) with that client
  app.configure(restClient.fetch(window.fetch));
  app.configure(
    auth({
      prefix: 'BEARER',
      storage: localStorage
    })
  );

  return app;
}

const mainApp = getApp(API_URL);

if(isAuthenticated()) {
  mainApp.reAuthenticate().catch((err) => {
    localStorage.removeItem('feathers-jwt');
    console.error(err);
    window.location.href = '/login';
  });
}

export async function authenticate(email, password) {
  let credentials;
  try {
    credentials = await mainApp.authenticate({
      strategy: 'local',
      email,
      password
    });
  } catch(err) {
    console.log(err);
    return err;
  } 
  
  const expireDate = new Date().getTime() + EXPIRE_IN;

  mainApp.set('accessToken', credentials.accessToken);
  mainApp.set('accessTokenExpireDate', expireDate);
  localStorage.setItem('user', JSON.stringify(credentials.user));

  return credentials;
}

mainApp.on('reauthentication-error', (err) => {
  localStorage.removeItem('feathers-jwt');
  localStorage.removeItem('user');
  console.error(err);
  window.location.href = '/login';
});

export function isAuthenticated() {
  return localStorage.getItem('feathers-jwt');
}

export function getUser() {
  return JSON.parse(localStorage.getItem('user'))
}

export function redirectIfNotAuthenticated() {
  if(window.location.pathname.startsWith('/verify') || window.location.pathname.startsWith('/login') || window.location.pathname.startsWith('/signup') || window.location.pathname.startsWith('/forgot-password') || window.location.pathname.startsWith('/reset-password')) {
    return false;
  }
  
  if(!isAuthenticated()) {
    console.error("err");
    localStorage.removeItem('feathers-jwt');
    localStorage.removeItem('user');
    window.location.pathname = '/login';
  }
}

export default {
  mainApp,
  // mainSocketApp,
  authenticate
};
