import React, { useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter, dateFilter,  } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';

import AppBar from './AppBar';

import client, { getUser } from './utils/api'; 

const Users = () => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [error, setError] = useState();
    const [totalSize, setTotalSize] = useState(0);
    const [sizePerPageState, setSizePerPage] = useState(200);
    const [savedFilters, setSavedFilters] = useState({});

    const handleDelete = async (id, name) => {
        if(window.confirm(`Supprimer ${name}?`)) {
            await client.mainApp.service('users').remove(id);
        }
    };

    const columns = [{
        dataField: 'email',
        text: 'Email',
        filter: textFilter(),
        sort: true,
        editable: true,
        style: {
            width: 200
        }
    },{
        dataField: 'name',
        text: 'Name',
        filter: textFilter(),
        sort: true,
        editable: true,
        style: {
            width: 200
        }
    },
    {
        dataField: 'salesforceId',
        text: 'ID salesforce',
        filter: textFilter(),
        sort: true,
        editable: true,
        style: {
            width: 200
        }
    },
    {
        dataField: 'isAdmin',
        text: 'Is Admin',
        sort: false,
        editable: true,
        editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          },
        style: {
            width: 200
        }
    }, {
        dataField: "remove",
        text: "",
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <button
              className="btn btn-danger btn-xs"
              onClick={() => {
                handleDelete(row._id, row.name)}
              }
            >
              Delete
            </button>
          );
        },
        style: {
            width: 1
        }
      }];

    useEffect(() => {
        loadUsers({ $skip: page * sizePerPageState, $limit: sizePerPageState });
      }, []);

    const loadUsers = async (params) => {
        try {
            const c = await client.mainApp.service('users').find({ query: {
                    ...params
                }
            });
            setTotalSize(c.total);
            setUsers(c.data);
        } catch(err) {
            console.error(err);
            window.location.href = '/login';
        }
    };

    const user = getUser();

    const handleTableChange = async (type, { cellEdit, sortField, sortOrder, filters, page, sizePerPage }) => {
        if(cellEdit && cellEdit.rowId && (cellEdit.newValue || cellEdit.newValue === '')) {
            try {
                let updateObj = {
                    [cellEdit.dataField]: cellEdit.newValue
                };

                if(updateObj.isAdmin) {
                    updateObj.isAdmin = updateObj.isAdmin === 'true';
                }

                await client.mainApp.service('users').patch(cellEdit.rowId, updateObj);
            } catch(err) {
                setError("Erreur lors de la sauvegarde");
            }
    
            return  loadUsers({ $skip: (page - 1) * sizePerPage, $limit: sizePerPage });
        }

        const apiFilters = {...filters};
        
        setSizePerPage(sizePerPage)

        loadUsers({ $skip: (page - 1) * sizePerPage, $limit: sizePerPage });
    
    
        setSavedFilters({ sortField, sortOrder, filters: apiFilters, page: (page - 1), sizePerPage })
        setPage((page - 1));
      };

  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total" style={{marginLeft: 20, marginTop: 5}}>
      <span>{ from } à { to } sur { size } Résultats</span>
    </span>
  );

  const cellEdit = {
    mode: 'click',
    blurToSave: true,
    errorMessage: error
  };

  return (
    <React.Fragment>
        <AppBar name={user?.name} email={user?.email} />
        <div>
            <Link to="/users-management/new">Create new user</Link>
        </div>
        <div className="scrollTable">
            <BootstrapTable
                bootstrap4
                keyField='_id'
                striped
                wrapperClasses="table-responsive"
                data={ users?.map(u => ({
                    ...u,
                })) || []}
                onTableChange={ handleTableChange }
                columns={ columns } 
                filter={ filterFactory() } 
                pagination={ paginationFactory({ page, sizePerPage: sizePerPageState, totalSize, showTotal: true, paginationTotalRenderer }) }
                cellEdit={ cellEditFactory(cellEdit) }
                bordered={ false }
                noDataIndication="Pas de users"
                remote={{ filter: false, sort: false, cellEdit: true }}
            />
        </div>
    </React.Fragment>
  );
};

export default Users;
